import React from 'react'
import Article from '../Article'
import { Wrapper } from './styles'
import Card from './Card'

const SameDayDeliveryPage = () => {
  return (
    <Article>
      <Wrapper>
        <p className="introduction">
          W ostatnich latach sprzedawcy i firmy kurierskie przyzwyczaiły nas do
          tego, że nawet paczkę zamówioną z drugiego końca Polski mamy u siebie
          kolejnego dnia. Jest to niewątpliwie ogromny postęp, bo jeszcze 10 lat
          temu podobny transport trwał zwykle 2–4 dni. Na tę chwilę jedyną
          innowacyjną usługą, którą można wprowadzić na rynek kurierski, jest
          dostawa tego samego dnia! Dostawa Sameday, bo tak często jest
          nazywana, nie jest usługa tak tanią, jak tradycyjny kurier, jednak nie
          o cenę chodzi, a o czas realizacji. W życiu zdarza się wiele momentów,
          w których musimy coś dostarczyć niemal natychmiast, a sami fizycznie
          nie mamy takiej możliwości. Co w takim wypadku można zrobić?
        </p>
        <h3>
          Dostępność i gotowość 24h/365 dni w roku
          <br />
          <br />
          Zaangażowanie i indywidualne podejście do każdego klienta
        </h3>
        <p className="common">
          Oferujemy możliwość stałej współpracy z atrakcyjnymi rabatami dla
          firm.
        </p>
        <Card />
      </Wrapper>
    </Article>
  )
}

export default SameDayDeliveryPage
