/* eslint-disable react/jsx-pascal-case */

import React from 'react'
import Layout from 'src/hoc/Layout'
import Nav from 'components/Nav'
import SubPageHeader from 'components/SubPageHeader'

import SameDayDeliveryPage from 'components/SameDayDeliveryPage'
import SEO from 'components/SEO'

const SameDayDelivery = props => {
  return (
    <Layout>
      <SEO />
      <Nav {...props} black />
      <SubPageHeader title="Dostawa tego samego dnia" imageName="SAME_DAY" />
      <SameDayDeliveryPage />
    </Layout>
  )
}

export default SameDayDelivery
