import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Divider, Wrapper } from './styles'

const Card = () => {
  return (
    <>
      <Wrapper>
        <StaticImage src={'../../../images/package.jpg'} alt="Auto Transwell" />
        <div className="content">
          <h4>Najszybszy transport Śląsk - Katowice, Gliwice, Częstochowa</h4>
          <p>
            Dostawa tego samego dnia nie jest powszechnym rozwiązaniem. Jest to
            stosunkowo specjalistyczna usługa, której nie oferują wszystkie
            firmy kurierskie. Opisywana usługa często jest również nazywana X
            Press Couriers, co oznacza kuriera ekspresowego. Dlaczego duże firmy
            kurierskie najczęściej nie oferują tej usługi? Zwyczajnie im się to
            nie opłaca. Na tradycyjnym systemie kurierskim pojedynczy kierowca
            jest w stanie dnia dużo lepiej zarobić niż w kilku zamówieniach typu
            X Press Couriers. Ponadto utrzymywanie floty samochodów w każdym
            większym mieście wraz z pracownikami do zadań specjalnych jest
            kosztowne i najczęściej nieopłacalne dla przewoźnika. Pozostaje
            jeszcze problem samych pracowników i poszanowania paczek. Niestety
            firmy kurierskie nie mają najlepszych opinii. Każdego dnia zdarzają
            się sytuacje, w których giną paczki, zostają uszkodzone lub
            całkowicie zniszczone. Dla usług bardzo ekskluzywnych nie ma miejsca
            na takie zaniechania. Każda paczka, bez kompromisów i zgodnie z
            terminem musi, być dostarczona we wskazane miejsce.
          </p>
        </div>
      </Wrapper>
      <Divider />
      <Wrapper noMargin>
        <div className="content">
          <h4>Dostawa sameday – odbiór i dostawa tego samego dnia</h4>
          <p>
            W naszej firmie Transwell opisywana usługa dostępna jest w okolicy
            Katowic i całego województwa śląskiego. Obsługujemy także
            województwa łódzkie, dolnośląskie, opolskie i małopolskie.
            Oczywiście wszystko zależy od tego, skąd i dokąd ma być
            transportowana przesyłka oraz jaki mamy czas na jej dostarczenie.
            Jeśli będziemy musieli dostarczyć przesyłkę z Krakowa do Opola, a
            najbliższy samochód będzie w Gliwicach, to zwyczajnie klientowi może
            się to nie opłacać. Inaczej wygląda sytuacja, jeśli chcesz, aby
            Twoja przesyłka z Katowic znalazła się szybko w Częstochowie. W
            takim wypadku na pewno będziemy mogli pomóc szybko, a cena przesyłki
            nie będzie wygórowana.
          </p>
        </div>
        <div className="images">
          <StaticImage
            src={'../../../images/transwell_kato.jpg'}
            alt="Auto Transwell"
          />
          <StaticImage
            src={'../../../images/transwell_unpacking.jpg'}
            alt="Auto Transwell"
          />
        </div>
      </Wrapper>
      <Divider />
      <Wrapper>
        <div className="images-big">
          <StaticImage
            src={'../../../images/transwell_cars_tires.jpg'}
            alt="Auto Transwell"
          />
          <StaticImage
            src={'../../../images/big_car.png'}
            alt="Auto Transwell"
          />
          <StaticImage
            src={'../../../images/transwell_cars.jpg'}
            alt="Auto Transwell"
          />
        </div>
        <div className="content">
          <h4>Usługi kuriera na już – czy i kiedy warto skorzystać</h4>
          <p>
            Dlaczego dostawy z dnia na dzień są takie ważne? Usługi tego typu
            nieraz mogą nawet uratować życie. Oczywiście metaforycznie. Bardzo
            często zdarza się, że ktoś czegoś zapomni. W przypadku zapomnianego
            śniadania nie ma problemu, ale co, jeśli zapomnisz pen drive, na
            którym miałeś zapisaną całą prezentację, w trakcie której
            przemawiania jesteś. Powrót do domu i ponowny dojazd na miejsce może
            być niemożliwy. Jednak w takiej sytuacji natychmiastowa pomoc z
            naszej strony może okazać się niezastąpiona. Jest wiele sytuacji
            wewnątrz firmowych, w których trzeba dostarczyć ważne dokumenty na
            już, ale firma na daną chwilę nie dysponuje środkami ludzkimi, aby
            to zrobić. Transport wewnętrzny w firmie zwykle zapewnia tylko stałe
            elementy przewozu, które są powtarzalne, jak przewożenie elementów
            czy transportowanie produktów do innej hali lub bezpośrednio
            klientom. W sytuacjach kryzysowych może nie być nikogo „wolnego”,
            kto może rzucić swoją pracę, wskoczyć do auta i zawieźć dokumenty. W
            takim wypadku również jesteśmy i służymy pomocą.
          </p>
          <p>
            Jednak to nie wszystko. Zamówienia kurierskie są realizowane w
            konkretnych godzinach. Są sytuacje, w których przesyłek nie ma kto
            odebrać w ciągu dnia i pozostaje jedynie transport i dostawa nocna.
            Tradycyjny kurier nie ma możliwości dostarczenia przesyłek nocą,
            jednak my owszem. Jesteśmy w stanie zrealizować wszystkie zamówienia
            w różnych godzinach oraz dniach tygodnia, w tym także w weekendy.
          </p>
          <p>
            Poza tym dostawa tego samego dnia na terenie śląska i okolic
            realizuje również usługi na nietypowe przesyłki. Kurierzy
            najczęściej mają swoje limity, czy to wielkościowe, czy wagowe.
            Paczka musi ważyć tyle i tyle, a przesyłki powyżej pewnej wagi są
            obsługiwane tylko przez transporty paletowe. W naszym wypadku nie ma
            takich ograniczeń. Jesteśmy w stanie wykonać usługę za każdym razem,
            kiedy tylko jest możliwość bezpiecznego umieszczenia przesyłki w
            samochodzie.
          </p>
          <p>
            Warto jeszcze wrócić do czasu transportu. Często realizowaną usługą
            dla naszych klientów jest odbiór i dostarczenie elementów
            serwisowych na przykład w maszynach budowlanych. Pompa hydrauliczna
            przeważnie waży na tyle dużo, że pozostaje jedynie transport
            paletowy. Czas transportu paletowego może wynieść nawet 3–4 dni. A
            każdy dzień, kiedy maszyny są niesprawne, oznacza, że nie zarabiają.
            Jeszcze gorzej jest w przypadku, kiedy uszkodzeniu uległa kluczowa
            maszyna i bez niej cała budowa stoi. Wydatek na usługę X Press
            Couriers może okazać się najlepszym rozwiązaniem. A zaoszczędzone 3
            dni mogą być kluczowe, żeby ustrzec się wysokich kar umownych
            związanych z opóźnieniami na budowie.
          </p>
          <p>
            Jeśli potrzebujesz kuriera na już, zadzwoń do nas! Jeśli
            potrzebujesz transportu z Łodzi czy Opola, a Warszawa wydaje się być
            poza terenem naszego działania, również do nas zadzwoń. Może uda nam
            się pomóc nawet w tym wypadku!
          </p>
        </div>
      </Wrapper>
    </>
  )
}

export default Card
